<template>
  <v-container>
    <tasks-templates-list
      :title="title"
      :headers="headers"
      :data="data"
      :response="response"
      :loading="loading"
      :create-task-target="'TasksNew'"
      @reload="loadTemplates"
      @new="newFn"
      @edit="editFn"
      @remove="removeFn"
    ></tasks-templates-list>
    <delete-dialog
      :dialog="deleteDialog"
      :item="templateName"
      @agree="removeHandler"
      v-show="false"
    />
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import TasksTemplatesList from "@/components/lists/TemplatesList.vue";
import snackbar from "@/utils/snackbar.js";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
export default {
  components: {
    TasksTemplatesList,
    DeleteDialog,
  },
  data: () => ({
    headers: [
      {
        text: "Title",
        align: "left",
        value: "name",
      },
      {
        text: "Boat",
        align: "left",
        value: "boat",
      },
      {
        text: "Visibility",
        align: "left",
        value: "crm_group",
      },
      { text: "", align: "right", value: "action", sortable: false },
    ],
    data: [],
    sort: "name",
    title: "Inventory",
    deleteDialog: false,
    templateName: null,
    template: {},
    boat: null,
    boatName: null,
    loading: true,
    response: null,
  }),
  created() {
    if (this.$route.query.page || this.$route.query.search) {
      this.loadTemplates(this.$route.query);
    } else {
      this.loadTemplates();
    }
  },
  methods: {
    loadTemplates(params) {
      if (!params) {
        params = {};
      }
      this.loading = true;
      this.data = [];
      // api.getTemplateList(params).then((resp) => {
      const resp = {
        count: 9,
        next: null,
        previous: null,
        results: [
          {
            uuid: "0ac20bb98dbc481e99c54c9fde0063fb",
            checklists: [
              {
                uuid: "0b249f00b2114d52a15e62e31e2d9f1e",
                name: "Oceanis 44",
                subtasks: [
                  {
                    uuid: "8347acc40ca1413fb89512f26e80b449",
                    description: "cockpit table",
                  },
                  {
                    uuid: "65a26f15b3be4c11968f538c1bf3675d",
                    description: "dinghy",
                  },
                  {
                    uuid: "5e5a6caf8bbe49bfa443cf0762b0e933",
                    description: "wind direction indicator",
                  },
                  {
                    uuid: "aa4cbb1bb00e45409391c4c4a3503c11",
                    description: "steering wheel with compass",
                  },
                  {
                    uuid: "be6f21b246fd4a9092a0ca42ea77b2ff",
                    description: "speedometer and echo sounder",
                  },
                  {
                    uuid: "d7c32b7108b442d09a4fd928674fae48",
                    description: "bilge pump",
                  },
                  {
                    uuid: "8a131058e4f243c5ad7a2d9fd1594e74",
                    description: "Gangway board",
                  },
                  {
                    uuid: "2b1a1b5898af49bb9971c603b3d175b9",
                    description: "life buoy with light",
                  },
                  {
                    uuid: "83b0b862fdba41cfaa5a570aa7d1e3a0",
                    description: "GPS+Charterplotter",
                  },
                  {
                    uuid: "823f8cb8d6f244dc8e6c6ce5c82c023b",
                    description: "main sail",
                  },
                  {
                    uuid: "3e39f01f2a23407e9276fe3070e31757",
                    description: "roll genoa",
                  },
                  {
                    uuid: "13396c79b7e742d183bb9a47df9a5f0d",
                    description: "flag",
                  },
                  {
                    uuid: "96fe226ee4424c768cfb409d7786814d",
                    description: "boat hook",
                  },
                  {
                    uuid: "90a42a61c4794abaaf2d69c3f2371dc6",
                    description: "ultra sound",
                  },
                ],
                checklist_type: "GUEST_CHECKIN",
                crm_boat: null,
                attachments: [],
              },
            ],
            crm_group: {
              uuid: "a96cfb74f9b14157b93ecf3478f13b19",
              name: "Charter Sentinel",
              is_supergroup: true,
              is_range: false,
              parent_uuid: null,
            },
          },
          {
            uuid: "7b80a99a598a40c68893103e91081712",
            checklists: [
              {
                uuid: "41ea194476b14a559bf2e9f97578759c",
                name: "Oceanis 46",
                subtasks: [
                  {
                    uuid: "4becc014a69645e8b88e3d72049134e5",
                    description: "flag",
                  },
                  {
                    uuid: "de0e09e6ca4d44bf84a8de133619852c",
                    description: "life buoy with light",
                  },
                  {
                    uuid: "6da6e0657a8241eab60eaec6954bdbec",
                    description: "cockpit table",
                  },
                  {
                    uuid: "30b441f9b38246729e3df76100361970",
                    description: "dinghy",
                  },
                  {
                    uuid: "006a819beb7b4d30a99f1c74a84e901d",
                    description: "wind direction indicator",
                  },
                  {
                    uuid: "43fcfa12542344709b446c791abf6088",
                    description: "steering wheel with compass",
                  },
                  {
                    uuid: "a85dd38b83394f5a9bb0dae08167c306",
                    description: "speedometer and echo sounder",
                  },
                  {
                    uuid: "67c4a29ce0364f819c72f17033bcd081",
                    description: "bilge pump",
                  },
                  {
                    uuid: "ce687e28ea6b4db5815881c562bde987",
                    description: "Gangway board",
                  },
                  {
                    uuid: "5940dbff983e40ca8daa63c18e0317a3",
                    description: "bimini top",
                  },
                  {
                    uuid: "12f184581e724f7e8568252873990f57",
                    description: "GPS+Charterplotter",
                  },
                  {
                    uuid: "feae5d1596904444896e6c1a50eec213",
                    description: "main sail",
                  },
                  {
                    uuid: "8406c4595b724d35b0a7a89d63a14c44",
                    description: "roll genoa",
                  },
                  {
                    uuid: "73d91058be2640c48241d6a8a5d2e401",
                    description: "fenders",
                  },
                  {
                    uuid: "47ad9a1f0b124ba2b043b5eac85d93f3",
                    description: "boat hook",
                  },
                ],
                checklist_type: "GUEST_CHECKIN",
                crm_boat: null,
                attachments: [],
              },
            ],
            crm_group: {
              uuid: "a96cfb74f9b14157b93ecf3478f13b19",
              name: "Charter Sentinel",
              is_supergroup: true,
              is_range: false,
              parent_uuid: null,
            },
          },
          {
            uuid: "ff9d4a64ff634a8693c0f09da45e7e63",
            checklists: [
              {
                uuid: "867777aaba21426581eefb05e1fd6fee",
                name: "Oceanis 51",
                subtasks: [
                  {
                    uuid: "b5770b8cda49495ea69545a41ce47321",
                    description: "cockpit table",
                  },
                  {
                    uuid: "f267172795b8485eb35fef1b623e4e97",
                    description: "dinghy",
                  },
                  {
                    uuid: "3022d5250fe04f8cb506728c8b88a79a",
                    description: "wind direction indicator",
                  },
                  {
                    uuid: "e25fc318fba843b7b0c38abb8c4c87e1",
                    description: "steering wheel with compass",
                  },
                  {
                    uuid: "9404614e5570471fb1333f8c4e57bdf6",
                    description: "speedometer and echo sounder",
                  },
                  {
                    uuid: "f0e6c7f346044a5f87600faad7f85329",
                    description: "bilge pump",
                  },
                  {
                    uuid: "e46f1a8d42924529bd2da9358387b2f8",
                    description: "Gangway board",
                  },
                  {
                    uuid: "72d760488377411ba24063dee12fea51",
                    description: "bimini top",
                  },
                  {
                    uuid: "62f0ff87b4264a41bdcb30c30d898e7e",
                    description: "GPS+Charterplotter",
                  },
                  {
                    uuid: "dc0b9a04afc94a9782c37769e4f68c0e",
                    description: "main sail",
                  },
                  {
                    uuid: "cd2d9e587fa242c9b7509e9ceff2b18f",
                    description: "roll genoa",
                  },
                  {
                    uuid: "1d38d22dad644e92a61c88a8c247f7bd",
                    description: "flag",
                  },
                  {
                    uuid: "5e160d8370f9411d8dba22e1ac667d32",
                    description: "fenders",
                  },
                  {
                    uuid: "5010e276cb664e9da5e6d7c825951792",
                    description: "boat hook",
                  },
                  {
                    uuid: "b38fc6fe9fe5462ba047931fb4d054f8",
                    description: "life buoy with light",
                  },
                ],
                checklist_type: "GUEST_CHECKIN",
                crm_boat: null,
                attachments: [],
              },
            ],
            crm_group: {
              uuid: "a96cfb74f9b14157b93ecf3478f13b19",
              name: "Charter Sentinel",
              is_supergroup: true,
              is_range: false,
              parent_uuid: null,
            },
          },
        ],
      };
      this.response = resp;
      this.data = resp.results;
      this.loading = false;
      // });
    },

    editFn(task) {
      this.$router.push({
        name: "InventoryEdit",
        params: { id: task.uuid },
      });
    },

    removeFn(template) {
      // if (template.checklists.length === 0) {
      //   this.templateName = "Checklist 1";
      // } else {
      //   this.templateName = template.checklists[0].name;
      // }
      // this.template = template;
      // this.deleteDialog = true;
    },

    removeHandler(agreed) {
      // if (agreed) {
      //   api.deleteTemplate(this.template.uuid).then((resp) => {
      //     snackbar.success("Successfully deleted");
      //     this.loadTemplates(this.$route.query);
      //   });
      // }
      // this.deleteDialog = false;
    },

    newFn(task) {
      this.$router.push({
        name: "InventoryNew",
      });
    },
  },
};
</script>
